import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getOrderTables } from "../../../services/inventory";
import { useState } from "react";

const useTableView = () => {
  const { store_id } = useParams();
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [queryParams, setQueryParams] = useState({
    store_id: store_id,
  });

  const {
    isLoading: isLoadingOrderTable,
    data: orderTables,
    refetch: refetchOrderTables,
  } = useQuery(["getOrderTables", queryParams], () =>
    getOrderTables("", queryParams).then((res) => res.data),
  );

  const handleRefresh = () => {
    setIsLoadingRefresh(true);
    refetchOrderTables();
    setTimeout(() => {
      setIsLoadingRefresh(false);
    }, 1000);
  };

  const handleSearchTables = (value) => {
    const newQueryParams = { ...queryParams };
    if (value?.length === 0) delete newQueryParams.query;
    else newQueryParams.query = value;
    delete newQueryParams.page;
    setQueryParams(newQueryParams);
  };

  const gotoPage = (pageNumber) => {
    const newQueryParams = { ...queryParams };
    newQueryParams.page = pageNumber;
    setQueryParams(newQueryParams);
  };

  return {
    isLoadingOrderTable,
    orderTables: orderTables?.data || [],
    metaDetails: orderTables?.meta | {},
    isLoadingRefresh,
    gotoPage,
    handleRefresh,
    refetchOrderTables,
    handleSearchTables,
  };
};

export default useTableView;
