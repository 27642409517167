import styles from "./BillsWidget.module.scss";
import primaryComponents from "../../primaryComponents";
import Pagination from "../Pagination/Pagination";
import { useBillsWidget } from "./useBillsWidget";
import LoaderHelper from "../LoaderHelper/LoaderHelper";
import secondaryComponents from "..";
import Orderflows from "../StoreCheckout/Orderflows";
import OrderCancel from "./orderCancel";

const BillsWidget = ({ closeWidget, cancelCurrentOrder, tableCardId }) => {
  const {
    bills,
    instance,
    isFetchingBills,
    billPageDetails,
    selectedOrder,
    setSelectedOrder,
    gotoPage,
    handleSearchOrders,
    handleFilterByPayment,
    isOrderCancel,
    setIsOrderCancel,
    billWasUpdated,
    setBillWasUpdated,
  } = useBillsWidget({
    tableCardId,
    closeWidget,
    cancelCurrentOrder,
  });
  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <div className={styles.billsWidget}>
        <div className={styles.billsWidgetHeader}>
          <div className={styles.searchSection}>
            <primaryComponents.SearchField
              placeholder={"Search order by order ID or table name"}
              trigger={(value) => handleSearchOrders(value)}
              triggerOnInput={true}
            />
          </div>
          <div style={{ maxWidth: "200px", width: "100%" }}>
            <primaryComponents.SelectField
              value="Unpaid"
              options={[
                { label: "All", value: "" },
                { label: "Paid", value: "COMPLETED" },
                { label: "Unpaid", value: "bill" },
              ]}
              onChange={(value) => {
                handleFilterByPayment(value);
              }}
              nameKey="label"
              idKey="value"
              isSearchable={false}
              floatError={false}
            />
          </div>
        </div>
        <div className={styles.billsWidgetContent}>
          <LoaderHelper isLoading={isFetchingBills} classNames={"mt-5"}>
            <secondaryComponents.TableHelper
              getTableBodyProps={getTableBodyProps}
              getTableProps={getTableProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
              handleScroll={true}
            >
              <h4 className="mt-5 text-center">No Data Available</h4>
            </secondaryComponents.TableHelper>
          </LoaderHelper>
        </div>
      </div>
      {bills?.length > 0 && billPageDetails?.next_page ? (
        <Pagination
          totalPageNumber={billPageDetails?.last_page}
          handlePageClick={(value) => gotoPage(value.selected + 1)}
          currentPage={billPageDetails?.current_page}
        />
      ) : null}

      <secondaryComponents.SideModal
        isActive={selectedOrder !== null}
        closeModal={() => setSelectedOrder(null)}
        width={1100}
      >
        <Orderflows
          closeModal={() => setSelectedOrder(null)}
          orderId={selectedOrder}
          defaultLanding={"tnxReceipt"}
          viaSaleRecord={true}
        />
      </secondaryComponents.SideModal>

      {/* modal */}
      <secondaryComponents.Modal
        isActive={isOrderCancel !== null}
        closeModal={() => setIsOrderCancel(null)}
        width={500}
      >
        <OrderCancel
          closeWidget={() => setIsOrderCancel(null)}
          orderDetails={isOrderCancel}
          cancelCurrentOrder={cancelCurrentOrder}
        />
      </secondaryComponents.Modal>

      <secondaryComponents.SideModal
        isActive={billWasUpdated !== null}
        closeModal={() => setBillWasUpdated(null)}
        width={1100}
      >
        <secondaryComponents.BillUpdate
          orderId={billWasUpdated}
          resetView={() => setBillWasUpdated(null)}
          setBillWasUpdated={setBillWasUpdated}
          isOrderFlow={false}
        />
      </secondaryComponents.SideModal>
    </>
  );
};

export default BillsWidget;
